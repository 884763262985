<template>
  <div class="center">
    <header>
      <span class="logo"></span>
      <span class="time">{{ ccountDownTime }}</span>
    </header>
    <main>
      <!-- 订单号 订单时间 -->
      <div class="content">
        <table class="payment">
          <tr>
            <th class="payment-title">Total Amount</th>
            <th class="payment-value">{{ queryObject.currency }} {{ queryObject.amount / 100 }}</th>
          </tr>
          <tr>
            <th class="payment-title">Send To</th>
            <th class="payment-value">{{ queryObject.bankName }}</th>
          </tr>
          <tr>
            <th class="payment-title">Card No</th>
            <th class="payment-value">
                            <span @click="copy(queryObject.bankCardNo)"
                                  class="payment-phone">{{ queryObject.bankCardNo }}</span></th>
          </tr>
        </table>
        <button class="payment-btn" @click="openDo">I have made this payment</button>
      </div>

      <div class="paymentinfo-md">
        <!--        <div class="payment-info-title two-pl">实例转账编号: 202121324551212121</div>-->
        <!--        <div class="payment-info-input-md">-->
        <!--          <div class="input-orderCode">-->
        <!--            <a class="sumbit-but" @click="submitbut" title="Submit Ref Number">完成转账</a>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="paymentinfo-md">
          <div class="paymentinfo-md">
            <div class="iconfont">
              {{
                queryObject.state == 0 ? '&#xe627;'
                    : queryObject.state == 1 ? '&#xe600;'
                    : queryObject.state == 2 ? '&#xe677;'
                        : queryObject.state == 3 ? '&#xe95e;'
                            : queryObject.state == 4 ? '&#xe63f;'
                                : queryObject.state == 5 ? '&#xe623;'
                                    : queryObject.state == 6 ? '&#xe95e;' : '&#xe95e;'
              }}
            </div>
            <div class="orderCode-md">{{
                queryObject.state == 0 ? '订单生成中'
                    : queryObject.state == 1 ? '待支付（如已支付请等待审核）'
                    : queryObject.state == 2 ? '支付成功'
                        : queryObject.state == 3 ? '支付失败'
                            : queryObject.state == 4 ? 'Had withdrawn'
                                : queryObject.state == 5 ? 'refunded'
                                    : queryObject.state == 6 ? '订单已关闭' : 'unknown'
              }}
            </div>
          </div>
        </div>
      </div>

      <el-dialog
          :visible.sync="centerDialogVisible"
          width="80%"
          :show-close="false"
          center>
        <span slot="title">
            <h4>
                if paying with other mpesa numbers,please add the transfer voucher.(EG:QAD9JH9EDR)
                </h4>
        </span>
        <input class="payment-input" type="text" v-model="transfer.upiRefOrderId"
               placeholder="Please enter order number">
        <span slot="footer" class="dialog-footer">
    <el-button style="font-size:0.4rem;float: left;" @click="cancel">Cancel</el-button>
    <el-button style="font-size:0.4rem;float: right;" type="primary" @click="submit">Confirm</el-button>
          <div style="clear:both;"></div>
  </span>
      </el-dialog>
    </main>
  </div>
</template>
<script>

import {cardPushACardOrder, cardQueryACardOrder} from '@/api/api'
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import config from "@/config";
import "dayjs/locale/zh-cn";

dayjs.extend(duration);
dayjs.locale("zh-cn");
export default {
  data() {
    return {
      centerDialogVisible: false,//是否打开dialog
      queryObject: {},
      isPayFlag: false, // 是否能支付
      ccountDownTime: '',
      orderId: null,
      setIntervals: null,
      // 用户付款信息
      transfer: {
        payOrderId: '',
        upiRefOrderId: ''
      }
    }
  },
  components: {},
  mounted() {
    this.queryOrderInfo()
    setInterval(() => {
      this.queryOrderInfo()
    }, 2000)

    // 弹出层
    this.setIntervals = setInterval(() => {
      this.countdown()
    }, 1000)
  },
  methods: {
    queryOrderInfo() {
      // 请求后台获取 获取个卡订单
      cardQueryACardOrder(this.$route.params.id).then(res => {
        this.queryObject = res
      }).catch(() => {
        // this.$message({type: 'error', message: 'The order is expired, do not submit!', offset: 400})
        this.$router.push({
          name: config.errorPageRouteName,
          params: {errInfo: 'The order is expired, do not submit！'}
        })
        return;
      })
    },
    countdown() {
      if (this.queryObject.state <= 1) {
        const diffTime = dayjs.duration(dayjs(this.queryObject.expiredTime) - dayjs());
        // const day = diffTime.days(); //天
        const hours = diffTime.hours(); //小时
        const minutes = diffTime.minutes(); //分钟
        const seconds = diffTime.seconds(); //秒
        this.ccountDownTime = `${hours}:${minutes}:${seconds}`
        if (hours <= 0 && minutes <= 0 && seconds <= 0) {
          if (this.queryObject.state != 2) {
            this.isPayFlag = true
          }
          this.ccountDownTime = `0:0:0`
          clearInterval(this.setIntervals)
        }
        // 订单过期
        if (this.isPayFlag) {
          this.$router.push({name: config.errorPageRouteName, params: {errInfo: 'Order expired!'}})
        }
      } else {
        this.ccountDownTime = `0:0:0`
        clearInterval(this.setIntervals)
      }
    },
    openDo() {
      // 订单过期
      if (this.isPayFlag) {
        this.$message({type: 'error', message: 'The order is expired, do not submit!', offset: 400})
        return
      }
      if (this.queryObject.payOrderId === undefined) {
        this.$message({type: 'error', message: 'The current page parameter is wrong', offset: 400})
        return
      }
      this.centerDialogVisible = true
    },
    copy(val) {
      var input = document.createElement("input"); // 创建input对象
      input.value = val; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message({type: 'success', dangerouslyUseHTMLString: true, message: 'copy success', offset: 400})
    },
    submit() {
      if (this.transfer.upiRefOrderId === undefined || this.transfer.upiRefOrderId === '') {
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: '<div style="width: 300px;">Please enter the transaction serial number!</div>',
          offset: 400
        })
        return;
      }
      this.transfer.payOrderId = this.queryObject.payOrderId
      // 提交用户付款信息
      cardPushACardOrder(this.transfer).then(res => {
        console.log(res)
        this.cancel()
        this.$message({type: 'success', message: 'Payment Successful!', offset: 400})
      }).catch(err => {
        console.log(err)
        this.$message({type: 'error', message: 'Payment Failed!', offset: 400})
      })
    },
    cancel() {
      this.centerDialogVisible = false
      this.transfer = {}
    }
  }
}
</script>

<style lang="css" scoped>
.center {
  background-color: #42B354;
  height: 100vh;
}

main {
  font-size: 30px;
  padding: 0.4rem;
  margin-top: 1rem;
}

header {
  background-color: #ffffff;
  padding: 0.4rem;
  display: flex;
  justify-content: space-between;
}

.logo {
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-align: left;
  font-weight: bold;
}

.time {
  font-size: 0.8rem;
  vertical-align: middle;
}

.content {
  padding: 0.4rem;
  border-radius: 0.2rem;
  background-color: #ffffff;
}

.payment-btn {
  border-radius: 10px;
  color: #ffffff;
  border: none;
  padding: 0.2rem 0.8rem;
  background-color: #42B354;
  margin-top: 1rem;
  font-size: 0.5rem;
}

.payment {
  width: 100%;
  font-size: 0.5rem;
}

.payment-title {
  text-align: left;
  padding: 0.2rem;
}

.payment-value {
  position: relative;
  color: #4b8a53;
}

.payment-input {
  border-radius: 10px;
  border: 1px solid darkgrey;
  width: 100%;
  height: 0.6rem;
  padding: 0.5rem;
  font-size: 0.5rem;
}

.payment-phone::before {
  content: 'Click to copy';
  position: absolute;
  top: 0.8rem;
  left: 25%;
  border-radius: 15px;
  background: #29C845;
  color: #ffffff;
  font-size: 0.3rem;
  padding: 0.1rem;
}

>>> .el-input__inner {
  height: 0.8rem;
}


.paymentinfo-md {
  margin-bottom: 11px;
}

.payment-info-title {
  text-align: left;
}

.input-orderCode {
  position: relative;
  margin: 0.4rem 0;
}

.input-orderCode > label {
  position: absolute;
  top: 0.4rem;
  left: 0.25rem;
}

.inpu-orderCode {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: right;
  padding: 0.4rem 0.2rem 0.4rem 3rem;
  font-size: 0.45rem;
  border: 0px;
  outline: none;
}

.sumbit-but {
  display: inline-block;
  width: 100%;
  font-size: 0.45rem;
  background-color: #2a3dee;
  padding: 0.25rem 0px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
}

.el-icon-upload2 {
  background-image: url("~@/assets/images/相机.png");
}

.select-img > div {
  display: block;
  width: 2.8rem;
  border: 0.013333rem solid #cac8c8;
  height: 2.8rem;
  background-image: url("~@/assets/images/相机.png");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: 50%;
  outline: none;
}

.select-img > div > img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.swal2-content {
  font-size: 0.425rem !important;
}

.swal2-actions {
  flex-wrap: nowrap !important;
  margin: 0.45em auto 0;
  overflow: hidden;
}

.swal2-actions > button {
  width: 50%;
  font-size: 0.525rem !important;
  text-align: center;
  /*border-radius: 0 !important;*/
  border-color: #ffffff;
}

.swal2-content {
  padding: 0.55rem 0 0 0;
}

.swal2-actions > button:nth-child(1) {
}

.swal2-actions > button:nth-child(2) {
  color: #a7a7a7 !important;
}

.swal2-styled {
  padding: 0.625em 0em;
  margin: 0;
  background-color: white !important;
}

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-title {
  font-size: 0.5em !important;
}

.swal2-icon.swal2-warning {
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem !important;
  font-weight: 800 !important;
}

#swal2-content {
  text-align: left;
  padding: 0 0.5rem;
}

.okbut {
  border: 0;
  width: 100% !important;
  margin: 0 0.45rem;
  border-radius: 1rem !important;
  color: #ffffff !important;
  margin-bottom: 0.3rem;
  background: -webkit-gradient(linear, 80 -82, -48 bottom, from(#f27005), to(#ededed)) !important; /*Safari */
  background: -moz-linear-gradient(top, #f27005, #ededed) !important; /*Firefox*/
}

.layui-layer-loading .layui-layer-content {
  width: 1.4rem !important;
  height: 0.6rem !important;
}

.layui-layer-hui .layui-layer-content {
  padding: 0.46rem 0.433333rem !important;
  text-align: center;
}

.layui-layer-dialog .layui-layer-content {
  font-size: 0.386667rem !important;
}

.amount_title {
  margin: 0.3rem 0;
}

.amount_title_mod {
  font-size: 1.00rem;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
}

.currency_mod {
  font-size: 0.50rem;
  margin-right: 0.15rem;
}

.lable-var-md {
  display: flex;
  align-items: center;
}

.national_flag {
  width: 1.5rem;
  height: 1rem;
}

@font-face {
  font-family: 'iconfont';
  src: url('~@/assets/font/iconfont.woff2?t=1657007420200') format('woff2'),
  url('~@/assets/font/iconfont.woff?t=1657007420200') format('woff'),
  url('~@/assets/font/iconfont.ttf?t=1657007420200') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 2.320rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


</style>


